<!--
 * @Author: your name
 * @Date: 2021-06-09 13:57:46
 * @LastEditTime: 2021-07-23 13:43:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \education_resource\src\views\index.vue
-->
<template>
  <div class="container">
    <header class="d-f a-c j-c">
      {{ fileName }}
    </header>
    <section class="d-f f-d a-c">
      <img src="@/assets/image/preview_nodata.png" class="bad" v-if="isBad" />
      <img :src="src" class="pdf" v-if="isPdf && !isBad" />
      <img :src="src" class="ppt" v-if="isPpt && !isBad" />
      <video :src="fileUrl" v-if="isVideo && !isBad" controls></video>
    </section>
    <footer class="d-f a-c" v-if="!isVideo && !isBad">
      <div class="first" title="首页" @click="toFirst">
        <img src="@/assets/image/preview_first.png" alt="" />
      </div>
      <div class="prev" title="上一页" @click="toPrev">
        <img src="@/assets/image/preview_prev.png" alt="" />
      </div>
      <div class="ipt d-f a-c j-c" @click="changeIpt" v-if="canShow">
        <span>{{ pageNum }}&nbsp;</span>/&nbsp;{{ total }}
      </div>
      <a-input-number v-model="pageNum" :min="1" :max="total" @blur="onChange" v-else />
      <div class="next" title="下一页" @click="toNext">
        <img src="@/assets/image/preview_next.png" alt="" />
      </div>
      <div class="last" title="尾页" @click="toLast">
        <img src="@/assets/image/preview_last.png" alt="" />
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Preview",
  data() {
    return {
      fileId: 474,
      pageNum: 1,
      total: 0,
      fileName: "",
      imageUrls: [],
      fileUrl: "",
      src: "",
      isBad: false,
      isPdf: true,
      isPpt: false,
      isVideo: false,
      canShow: true,
    };
  },
  methods: {
    //第一页
    toFirst() {
      if (this.pageNum === 1) {
        this.$message.info("已经是第一页了~");
      } else {
        this.pageNum = 1;
        this.jump();
      }
    },
    //最后一个
    toLast() {
      if (this.pageNum === this.total) {
        this.$message.info("已经是最后一页了~");
      } else {
        this.pageNum = this.total;
        this.jump();
      }
    },
    //上一页
    toPrev() {
      if (this.pageNum === 1) {
        this.$message.info("已经是第一页了~");
      } else {
        this.pageNum--;
        this.jump();
      }
    },
    //下一页
    toNext() {
      if (this.pageNum === this.total) {
        this.$message.info("已经是最后一页了~");
      } else {
        this.pageNum++;
        this.jump();
      }
    },
    changeIpt() {
      this.canShow = !this.canShow;
    },
    onChange() {
      this.canShow = !this.canShow;
      this.jump();
    },
    jump() {
      this.$api
        .getImageUrls({ fileId: this.fileId, pageNum: this.pageNum })
        .then((res) => {
          this.imageUrls = res.data.imagesUrls;
          this.src = this.imageUrls[0];
        });
    },
    //获取地址栏参数
    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let arr = query.split("&");
      for (let i = 0; i < arr.length; i++) {
        var pair = arr[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    //详细信息
    getImageUrls() {
      this.$api
        .getImageUrls({ fileId: this.fileId, pageNum: this.pageNum })
        .then((res) => {
          console.log(res);
          this.fileName = res.data.fileName;
          this.fileUrl = res.data.fileUrl;
          this.imageUrls = res.data.imagesUrls;
          this.total = res.data.pageCount;
          if (this.imageUrls && this.imageUrls.length > 0) {
            this.isBad = false;
            this.src = this.imageUrls[0];
          } else {
            this.isBad = true;
          }
          if (res.data.typeIdentify === "word" || res.data.typeIdentify === "pdf") {
            this.isPdf = true;
            this.isPpt = false;
            this.isVideo = false;
          } else if (res.data.typeIdentify === "ppt") {
            this.isPdf = false;
            this.isPpt = true;
            this.isVideo = false;
          } else if (res.data.typeIdentify === "video") {
            this.isPdf = false;
            this.isPpt = false;
            this.isVideo = true;
            this.isBad = false;
          } else {
            this.isBad = true;
          }
        });
    },
  },
  created() {
    this.fileId = parseInt(this.getQueryVariable("fileId"));
    if (this.fileId) {
      this.getImageUrls();
    } else {
      this.$message.error("请输入正确的网址");
    }
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.container {
  width: 100%;
  min-height: 100vh;
  background: rgba(241, 243, 245, 0.39);
  position: relative;
  header {
    width: 100%;
    height: 64px;
    position: fixed;
    top: 0;
    background: #ffffff;
    font-size: 18px;
    font-family: "Microsoft YaHei";
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid rgba(217, 217, 217, 0.39);
  }
  section {
    width: 100%;
    video {
      margin-top: 90px;
      margin-bottom: 90px;
      width: 900px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    }
    .bad {
      width: 260px;
      margin-top: 90px;
      margin-bottom: 90px;
    }
    .pdf {
      margin-top: 90px;
      width: 512px;
      margin-bottom: 90px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    }
    .ppt {
      margin-top: 90px;
      width: 1200px;
      margin-bottom: 90px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    }
  }
  footer {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-top: 1px solid rgba(217, 217, 217, 0.39);
    position: fixed;
    bottom: 0;
    padding: 0 48px;
    box-sizing: border-box;
    .prev,
    .last {
      margin-left: 16px;
    }
    .ipt {
      width: 106px;
      height: 24px;
      background: rgba(246, 246, 246, 0.39);
      border: 1px solid #e8e8e8;
      border-radius: 2px;
      margin: 0 8px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #666666;
      span {
        color: @main-color;
      }
    }
    .ant-input-number {
      width: 106px;
      height: 24px;
      ::v-deep .ant-input-number-input {
        height: 100%;
        text-align: center;
      }
    }
    img {
      width: 24px;
      cursor: pointer;
    }
  }
}
</style>
